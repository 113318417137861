import { Submission } from '@wix/forms-ui/dist/types/types';
import { Member } from '@wix/ambassador-members-ng-api/types';
import { BookingsLineItemOption } from '@wix/bookings-checkout-api';
import { Form } from '../../types/ambassador/bookings/ambassador-services-catalog';
import { ContactDetails } from '../../types/ambassador/bookings/ambassador-bookings-v2-booking';
import { BusinessInfo, ComplexPhone } from '../../types/types';
import { BookingRequestKeyMappings } from './form-submission.mapper';

export const getContactDetailsWithBookingRequestKeyMappingsFromSubmission = ({
  submission,
  form,
}: {
  submission?: Submission;
  form?: Form;
}) => {
  if (!submission || Object.values(submission!).length === 0) {
    return {
      [BookingRequestKeyMappings.FIRST_NAME]: '',
      [BookingRequestKeyMappings.EMAIL]: '',
      [BookingRequestKeyMappings.PHONE]: '',
    };
  }

  const { nameFieldId, lastNameFieldId, emailFieldId, phoneFieldId } =
    getContactDetailsFormFieldIds(form!);

  const lastName = submission?.[lastNameFieldId!] as string;

  let phoneString = submission?.[phoneFieldId!] as string;

  if (typeof submission?.[phoneFieldId!] === 'object') {
    const phone = submission?.[phoneFieldId!] as ComplexPhone;
    phoneString = `${phone.countryCode}${phone.prefix}${phone.phone}`;
  }

  return {
    [BookingRequestKeyMappings.FIRST_NAME]: submission?.[
      nameFieldId!
    ] as string,
    ...(lastName ? { [BookingRequestKeyMappings.LAST_NAME]: lastName } : {}),
    [BookingRequestKeyMappings.EMAIL]: submission?.[emailFieldId!] as string,
    [BookingRequestKeyMappings.PHONE]: phoneString,
  };
};

const getContactDetailsFormFieldIds = (form: Form) => ({
  nameFieldId: form.firstName?.fieldId!,
  lastNameFieldId: form.lastName?.fieldId!,
  emailFieldId: form.email?.fieldId!,
  phoneFieldId: form.phone?.fieldId!,
});

export const getContactDetailsWithFieldIds = ({
  form,
  contactDetails,
}: {
  form?: Form;
  contactDetails?: ContactDetails;
}): Submission => {
  const { nameFieldId, lastNameFieldId, emailFieldId, phoneFieldId } =
    getContactDetailsFormFieldIds(form!);

  const name =
    contactDetails?.firstName! +
    (!lastNameFieldId && contactDetails?.lastName
      ? ` ${contactDetails?.lastName}`
      : '');

  return {
    [nameFieldId]: name,
    ...(lastNameFieldId ? { [lastNameFieldId]: contactDetails?.lastName } : {}),
    [emailFieldId]: contactDetails?.email,
    [phoneFieldId]: contactDetails?.phone,
  };
};

export const getCollapseFormValues = ({
  isServiceInCart,
  preFilledValues,
  editCollapseData,
  bookingsLineItemOptions,
  memberDetails,
  isBookOnBehalfEnabled = true,
  form,
  businessInfo,
}: {
  isServiceInCart?: boolean;
  preFilledValues?: Submission;
  editCollapseData?: boolean;
  bookingsLineItemOptions?: BookingsLineItemOption[];
  memberDetails?: Member;
  isBookOnBehalfEnabled?: boolean;
  form: Form;
  businessInfo: BusinessInfo;
}): ContactDetails | undefined => {
  let collapseFormValues: ContactDetails | undefined;
  let mappedPreFilledValues: ContactDetails | undefined;

  if (preFilledValues) {
    mappedPreFilledValues =
      getContactDetailsWithBookingRequestKeyMappingsFromSubmission({
        submission: preFilledValues,
        form,
      });
  }

  if (editCollapseData) {
    return undefined;
  }

  if (isServiceInCart) {
    const contactDetailsFromFirstBookingOnCart =
      (bookingsLineItemOptions?.length || 0) >= 1
        ? bookingsLineItemOptions?.[0]?.contactDetails
        : undefined;

    collapseFormValues = contactDetailsFromFirstBookingOnCart;

    delete collapseFormValues?.fullAddress;
  } else if (memberDetails?.contact && isBookOnBehalfEnabled) {
    const { lastNameFieldId } = getContactDetailsFormFieldIds(form!);
    if (
      businessInfo.isSMSReminderEnabled ||
      (!memberDetails?.contact?.firstName &&
        !mappedPreFilledValues?.firstName) ||
      (!memberDetails?.contact?.lastName &&
        lastNameFieldId &&
        !mappedPreFilledValues?.lastName) ||
      (form.phone?.userConstraints?.required &&
        (memberDetails.contact?.phones?.length === 0 ||
          !memberDetails.contact?.phones?.[0]?.trim()) &&
        !mappedPreFilledValues?.phone?.trim())
    ) {
      return undefined;
    }
    collapseFormValues = mappedPreFilledValues || {
      email: memberDetails.contact?.emails?.[0] || memberDetails.loginEmail,
      firstName: memberDetails.contact.firstName,
      lastName: memberDetails.contact.lastName,
      phone: memberDetails.contact?.phones?.[0],
      contactId: memberDetails.contact.contactId,
    };
  }

  return collapseFormValues;
};
